import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbSetting = (match: Match) => ({ title: 'Settings', key: 'setting' })
const crumbSettingList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'setting-list' })

const adminRoutes = [
  {
    path: 'setting/:tab',
    handle: { crumb: crumbSetting },
    lazy: async () => import('~/app/staff/views/setting/SettingIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbSettingList }
      }
    ]
  }
]

export default adminRoutes
import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbAudit = (match: Match) => ({ title: 'Audit', key: 'audit' })
const crumbAuditInspect = (match: Match) => ({ title: <Link to={match.pathname}>Inspect</Link>, key: 'audit-inspect' })
const crumbAuditList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'audit-list' })

const auditRoutes = [
  {
    path: 'audit/:tab',
    handle: { crumb: crumbAudit },
    lazy: async () => import('~/app/staff/views/audit/AuditIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbAuditList }
      }
    ]
  },
  {
    path: 'audit/view/:auditId',
    handle: { crumb: crumbAuditInspect },
    lazy: async () => import('~/app/staff/views/user/UserInspectView')
  }
]

export default auditRoutes
import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'
import { getCrumbFromPath } from '~/lib/util/navigation.util'

const crumbEnergyCalculation = (match: Match) => ({ title: 'Energy Calculations', key: 'energy-calculation' })
const crumbEnergyCalculationList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'energy-calculation-list' })
const crumbEnergyCalculationTab = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname, 'Details')}</Link>, key: 'energy-calculation-tab' })

const energyCalculationRoutes = [
  {
    path: 'energy-calculation',
    handle: { crumb: crumbEnergyCalculation },
    lazy: async () => import('~/app/staff/views/energy-calculation/EnergyCalculationIndexView'),
    children: [
      {
        path: ':tab',
        index: true,
        handle: { crumb: crumbEnergyCalculationList }
      },
      {
        path: ':tab/:extraId',
        handle: { crumb: crumbEnergyCalculationTab }
      }
    ]
  }
]

export default energyCalculationRoutes
import React, { useEffect, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import * as Sentry from '@sentry/react'
import { Badge, createTheme, MantineProvider, TextInput } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { NavigationProgress } from '@mantine/nprogress'
import reportWebVitals from './reportWebVitals'
import router from './config/router'
import instance from './instance'
import { publicUserService } from '~/module/user/public-user.service'
import Authorising from '~/app/staff/auth/Authorising'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/nprogress/styles.css'
import 'mantine-datatable/styles.css'
import './assets/scss/styles.scss'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import variantColorResolver from '~/app/staff/misc/Variants'
import { badgeNeutral } from '~/lib/util/styles.util'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV_NAME || process.env.NODE_ENV || 'dev',
  dsn: 'https://0773cb7df06747c8b313f9c5244f0bdc@o4504808823062528.ingest.sentry.io/4505090567372800'
})

sessionStorage.clear()

const AppRoot = () => {
  const [initialiseComplete, setInitialiseComplete] = useState(false)
  const [authConfig, setAuthConfig] = useState<any>({})

  useEffect(() => {
    (async () => {
      try {
        const authConfigResp = await publicUserService.getAuthConfig()

        await instance.createMsal(authConfigResp)

        setAuthConfig(authConfigResp)
        setInitialiseComplete(true)
      }
      catch (e) {

      }
    })()
  }, [])

  const theme = createTheme({
    breakpoints: {
      xl: '110em'
    },
    variantColorResolver: variantColorResolver,
    components: {
      Badge: Badge.extend({
        defaultProps: {
          radius: 'sm',
          color: badgeNeutral
        }
      }),
      TextInput: TextInput.extend({
        styles: {
          input: {
            height: 32,
            minHeight: 32
          }
        }
      })
    }
  })

  return (<>
    {initialiseComplete &&
      <MsalProvider instance={instance.msalInstance()}>
        <QueryClientProvider client={instance.queryInstance()}>
          <MantineProvider theme={theme}>
            <NavigationProgress />
            <Notifications />
            <RouterProvider router={router} />
          </MantineProvider>
        </QueryClientProvider>
      </MsalProvider>
    }

    {!initialiseComplete &&
      <Authorising />
    }
  </>)
}

instance.rootInstance().render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbEmail = (match: Match) => ({ title: 'Emails', key: 'email' })
const crumbEmailList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'email-list' })

const emailRoutes = [
  {
    path: 'email/:tab',
    handle: { crumb: crumbEmail },
    lazy: async () => import('~/app/staff/views/email/EmailIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbEmailList }
      }
    ]
  }

]

export default emailRoutes
import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbContract = (match: Match) => ({ title: 'Contracts', key: 'contract' })
const crumbContractList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'contract-list' })

const contractRoutes = [
  {
    path: 'contract/:tab',
    handle: { crumb: crumbContract },
    lazy: async () => import('~/app/staff/views/contract/ContractIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbContractList }
      }
    ]
  }
]

export default contractRoutes
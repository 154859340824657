import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbAlert = (match: Match) => ({ title: 'Alerts', key: 'alert' })
const crumbAlertList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'alert-list' })
const crumbAlertInspect = (match: Match) => ({ title: <Link to={match.pathname}>Inspect</Link>, key: 'alert-inspect' })

const alertRoutes = [
  {
    path: 'alert',
    handle: { crumb: crumbAlert },
    lazy: async () => import('~/app/staff/views/power-billing/alert/AlertIndexView'),
    children: [
      {
        index: true,
        path: 'list',
        handle: { crumb: crumbAlertList },
      }
    ]
  },
  {
    path: 'alert/view/:alertId/:tab',
    handle: { crumb: crumbAlert },
    lazy: async () => import('~/app/staff/views/power-billing/alert/AlertInspectView'),
    children: [
      {
        index: true,
        path: 'details',
        handle: { crumb: crumbAlertInspect }
      }
    ]
  }
]

export default alertRoutes
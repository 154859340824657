import React from 'react'
import { createBrowserRouter, Link, Navigate, RouteObject, useRouteError } from 'react-router-dom'
import { Params } from '@remix-run/router'
import App from '../../app/App'
import Layout from '../../app/staff/Layout'
import GlobalErrorPage from '../../app/common/GlobalErrorPage'
import { createStaffPath } from '~/lib/util/navigation.util'
import Error404 from '../../app/staff/error/Error404'
import Error from '../../app/staff/error/Error'
import ErrorAuth from '../../app/staff/error/ErrorAuth'
import Authorising from '~/app/staff/auth/Authorising'
import { IconHome } from '~/app/staff/icon'
import { isDev } from '~/lib/util/misc.util'

import userRoutes from './routes/user.route'
import emailRoutes from './routes/email.route'
import auditRoutes from './routes/audit.route'
import dataRoutes from './routes/data.route'
import devRoutes from './routes/dev.route'
import alertRoutes from './routes/alert.route'
import meterRoutes from './routes/meter.route'
import customerRoutes from './routes/customer.route'
import billingRoutes from './routes/billing.route'
import energyCalculationRoutes from './routes/energy-calculation.route'
import contractRoutes from '~/config/router/routes/contract.route'
import pueConfigurationRoutes from '~/config/router/routes/pue-configuration.route'
import adminRoutes from '~/config/router/routes/admin.route'
import pueValueRoutes from '~/config/router/routes/pue-value.route'

export interface Match {
  id: string
  pathname: string
  params: Params<string>
  data: unknown
  handle: any //todo
}

const crumbStaff = (match: Match) => ({ title: <Link to={createStaffPath()}><IconHome /></Link>, key: 'home' })
const crumbDashboard = (match: Match) => ({ title: <Link to={createStaffPath()}>Dashboard</Link>, key: 'dashboard' })
const crumbPowerBilling = (match: Match) => ({ title: 'Power Billing', key: 'powerbilling' })
const crumbProfile = (match: Match) => ({ title: <Link to={match.pathname}>Profile</Link>, key: 'user-profile' })
const crumbReleases = (match: Match) => ({ title: <Link to={match.pathname}>Releases</Link>, key: 'releases' })
const crumbEstate = (match: Match) => ({ title: <Link to={match.pathname}>Estate Overview</Link>, key: 'estate' })

const isChunkLoadError = (error: { message: string, name: string, request: string }): boolean => {
  const regEx = /Loading chunk .* failed/i
  return error.name === 'ChunkLoadError' || regEx.test(error.message) || error.request.endsWith('chunk.js')
}

const ErrorBoundary = () => {
  const error: any = useRouteError()
  if (isChunkLoadError(error)) {
    return <Navigate to={`/staff?goto=${window.location.pathname}`} replace />
  }

  return <Error />
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <GlobalErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/staff" replace />
      },
      {
        path: 'staff/authentication/error',
        element: <ErrorAuth />
      },
      {
        path: 'auth/sso/ms',
        element: <Authorising />
      },
      {
        path: 'staff',
        errorElement: <ErrorBoundary />,
        handle: { crumb: crumbStaff },
        element: <Layout />,
        children: [
          {
            path: 'welcome',
            handle: { crumb: crumbDashboard },
            lazy: () => import('~/app/staff/views/Welcome')
          },
          {
            path: '*',
            element: <Error404 />
          },
          {
            path: 'releases',
            handle: { crumb: crumbReleases },
            lazy: async () => import('~/app/staff/views/system/ReleasesView')
          },
          {
            path: 'profile',
            handle: { crumb: crumbProfile },
            lazy: async () => import('~/app/staff/views/profile/ProfileView')
          },
          {
            path: 'power-billing',
            handle: { crumb: crumbPowerBilling },
            children: [
              ...meterRoutes,
              ...alertRoutes
            ]
          },
          {
            path: 'estate/location?/:location?',
            handle: { crumb: crumbEstate },
            lazy: async () => import('~/app/staff/views/estate/EstateOverviewIndex')
          },
          ...customerRoutes,
          ...contractRoutes,
          ...billingRoutes,
          ...energyCalculationRoutes,
          ...pueConfigurationRoutes,
          ...pueValueRoutes,
          ...userRoutes,
          ...auditRoutes,
          ...emailRoutes,
          ...dataRoutes,
          ...adminRoutes,
          ...(isDev()? devRoutes: [])
        ]
      }
    ]
  }
]

const router = createBrowserRouter(routes)
export default router
import { createPolymorphicComponent, TextProps, Text as MantineText } from '@mantine/core'
import { CSSProperties, forwardRef } from 'react'

interface CustomTextProps extends TextProps {
  children: React.ReactNode
  ws?: 'normal' | 'nowrap'
}

const Text = createPolymorphicComponent<'div', CustomTextProps>(
  forwardRef<HTMLDivElement, CustomTextProps>(({ children, style, ws, ...others }, ref) => {
    const customStyles = { ...style, whiteSpace: ws ?? (style as CSSProperties)?.whiteSpace ?? 'normal' }

    return (<MantineText component="div" style={customStyles} {...others} ref={ref}>
      {children}
    </MantineText>)
  })
)

export { Text }
import { isNumber, isPositiveNumber } from '~/lib/util/string.util'

export const validateEmailsMatch = (emailA: string, emailB: string): boolean => {
  return (emailA.toLowerCase().replace(/'/g, '') === emailB.toLowerCase().replace(/'/g, ''))
}

export function validateDigitCount(str: string, count: number): boolean {
  const onlyDigits = str.replace(/[^\d]/g, '')
  return onlyDigits.length <= count
}

export function validatePrecisionLength(str: string, length: number): boolean {
  const tokens = str.split('.')
  if (tokens.length > 1 && tokens[1].length > length) {
    return false
  }

  return true
}

export const validateNumber = (name: string, value: string, maxDigits: number, maxPrecision: number, mustBePositive: boolean): string | undefined => {
  if (mustBePositive && !isPositiveNumber(value)) {
    return `${name} must be a positive numeric value`
  }
  if (!mustBePositive && !isNumber(value)) {
    return `${name} must be a numeric value`
  }
  if (!validateDigitCount(value, maxDigits)) {
    return `${name} must contain a maximum of ${maxDigits} digits`
  }
  if (!validatePrecisionLength(value, maxPrecision)) {
    return `${name} must contain a maximum of ${maxPrecision} decimal places`
  }
}

export const isValidNumber = (value: string, maxDigits: number, maxPrecision: number, mustBePositive: boolean) => {
  return validateNumber('', value, maxDigits, maxPrecision, mustBePositive) === undefined
}
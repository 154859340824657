import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'
import { getCrumbFromPath } from '~/lib/util/navigation.util'

const crumbCustomer = (match: Match) => ({ title: 'Customers', key: 'customer' })
const crumbCustomerInspect = (match: Match) => ({ title: <Link to={match.pathname}>Inspect</Link>, key: 'customer-inspect' })
const crumbCustomerList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'customer-list' })
const crumbCustomerTab = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname, 'Detail')}</Link>, key: 'customer-tab' })
const crumbCustomerContract = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname, '')} Details</Link>, key: 'customer-sub-inspect' })

const customerRoutes = [
  {
    path: 'customer',
    handle: { crumb: crumbCustomer },
    lazy: async () => import('~/app/staff/views/customer/CustomerIndexView'),
    children: [
      {
        path: ':tab',
        index: true,
        handle: { crumb: crumbCustomerList }
      }
    ]
  },
  {
    path: 'customer/view/:customerId',
    handle: { crumb: crumbCustomer },
    lazy: async () => import('~/app/staff/views/customer/CustomerInspectView'),
    children: [
      {
        path: ':tab',
        handle: { crumb: crumbCustomerTab }
      },
      {
        path: ':tab/:extraId',
        handle: { crumb: crumbCustomerContract }
      }
    ]
  }
]

export default customerRoutes
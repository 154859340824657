import React from 'react'
import { Result } from 'antd'
import { createStaffUrl } from '~/lib/util/navigation.util'
import { Text } from '~/lib/component/Text'

const ErrorAuth = () => {

  function onClickRetry() {
    localStorage.clear()
    window.location.href = createStaffUrl()
  }

  return (
    <Result
      style={{ marginTop: 80 }}
      status="error"
      title="Authorisation Error"
      subTitle={<>
        <Text>There appears to be an authorisation issue.</Text>
        <Text>If you require access, please raise a request with your line manager.</Text>
        <Text style={{marginTop: 15}}>
          <a onClick={onClickRetry}>Retry</a>
        </Text>
      </>}
    />
  )
}

export const Component = ErrorAuth
export default ErrorAuth
import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'
import { getCrumbFromPath } from '~/lib/util/navigation.util'

const crumbMeter = (match: Match) => ({ title: 'Meters', key: 'meter' })
const crumbMeterList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'meter-list' })
const crumbMeterHierarchy = (match: Match) => ({ title: <Link to={match.pathname}>Hierarchy</Link>, key: 'meter-hierarchy' })
const crumbMeterInspect = (match: Match) => ({ title: <Link to={match.pathname}>Inspect</Link>, key: 'meter-inspect' })
const crumbMeterListTab = (match: Match) => ({ title: <Link to={match.pathname}>{match.pathname}</Link>, key: 'meter-hierarchy' })
const crumbMeterTab = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname, 'Details')}</Link>, key: 'meter-tab' })

const meterRoutes = [
  {
    path: 'meter',
    handle: { crumb: crumbMeter },
    lazy: async () => import('~/app/staff/views/power-billing/meter/MeterIndexView'),
    children: [
      {
        index: true,
        path: 'list',
        handle: { crumb: crumbMeterList }
      },
      {
        path: 'hierarchy/:type?/:meterId?',
        handle: { crumb: crumbMeterHierarchy }
      }
    ]
  },
  {
    path: 'meter/view/:meterId',
    handle: { crumb: crumbMeter },
    children: [
      {
        index: true,
        path: ':tab',
        lazy: async () => import('~/app/staff/views/power-billing/meter/MeterInspectView'),
        handle: { crumb: crumbMeterTab }
      }
    ]
  }
]

export default meterRoutes
import _ from 'lodash'
import React from 'react'
import { UUID } from '~/lib/util/request.util'
import { isEmptyStr } from '~/lib/util/string.util'

export interface AnyProps {
  /**
   * Supporting any prop allows this component to be managed by Ant.Design's Form/Form.Item
   * components.
   */
  [key: string]: any
}

export type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

export function isProd(): boolean {
  return process.env.NODE_ENV === 'production'
}

export function isDev(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isTruthy(v: any): boolean {
  return !!v
}

export const isEmpty = (v: any) => {
  return (v === null || v === undefined || v === '')
}

export function seriouslyStopPropagation(event?: any): false {
  event && event.stopPropagation && event.stopPropagation()
  event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation && event.nativeEvent.stopImmediatePropagation()
  return false
}

export function mappedObjects<T, K>(data: any[], keyProperty: string): Map<K, T> {
  return data.reduce(
    (map, v) => {
      map.set(v[keyProperty], v)
      return map
    },
    new Map<number, T>()
  )
}

/**
 * The keyProperty supports dot notation to used nested object keys
 */
export function uniqueValues(records: any[], keyProperty: string): any[] {
  return Array.from(
    new Set(
      records
        .map(record => _.get(record, keyProperty))
        .filter(v => (v !== null && v !== undefined && v !== ''))
    )
  )
}

export function uniqueValueMap(records: any[], keyProperty: string, valProperty: string): Record<any, any> {
  return Object.fromEntries(
    Array.from(
      new Set(
        records
          .map(record => [record[keyProperty], record[valProperty]])
          .filter(p => (p[1] !== null && p[1] !== undefined && p[1] !== ''))
      )
    )
  )
}

export function chunkArray(records: any[], chunkSize: number): any[][] {
  const chunks = []
  for (let i = 0; i < records.length; i += chunkSize) {
    chunks.push(records.slice(i, i + chunkSize))
  }
  return chunks
}

export function checkNotEmptyId(id: UUID): void {
  if (isEmptyStr(id)) {
    throw new Error('ID cannot be empty')
  }
}
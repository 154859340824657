import { Configuration, LogLevel } from '@azure/msal-browser'
import { browserUrl } from '~/lib/util/navigation.util'

const loggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
  if (containsPii) {
    //return
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message)
      return

    case LogLevel.Info:
      console.info(message)
      return

    case LogLevel.Verbose:
      console.debug(message)
      return

    case LogLevel.Warning:
      console.warn(message)
      return

    default:
      return
  }
}

class AuthConfig {

  private clientId?: string
  private tenantId?: string

  setClientId(clientId: string) {
    this.clientId = clientId
  }

  setTenantId(tenantId: string) {
    this.tenantId = tenantId
  }

  /**
   * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
   */
  buildGraphConfig() {
    return {
      graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me' //e.g. https://graph.microsoft.com/v1.0/me
    }
  }

  buildMsalConfig() {
    const conf: Configuration = {
      auth: {
        clientId: this.clientId || '',
        authority: `https://login.microsoftonline.com/${this.tenantId || ''}`,
        redirectUri: `${browserUrl}/staff/auth/sso/ms`,
        postLogoutRedirectUri: `${browserUrl}/staff/logout`,
        //navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        secureCookies: false // true for production
      },
      system: {
        loggerOptions: {
          loggerCallback
        }
      },
      telemetry: {
        application: {
          appName: 'Ark of Truth [Development]',
          appVersion: '1.0.0'
        }
      }
    }
    return conf
  }

  buildAuthRequest() {
    return {
      scopes: [`${this.clientId || ''}/.default`]
    }
  }

  /**
   * Scopes you add here will be prompted for user consent during sign-in.
   * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
   * For more information about OIDC scopes, visit:
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
   */
  buildLoginRequest() {
    return {
      scopes: ['User.Read']
    }
  }
}

const authConfig = new AuthConfig()
export default authConfig
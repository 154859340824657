import React, { createContext, useContext } from 'react'
import { User } from '~/module/user/user.interface'

interface AuthProviderContextProps {
  user: User | null
}

interface AuthProviderProps extends AuthProviderContextProps, React.PropsWithChildren {

}

const AuthContext = createContext<AuthProviderContextProps>({ user: null})

export function useUser(): User | null {
  return useContext(AuthContext).user
}

export function useIsDeveloper(): boolean {
  const user = useContext(AuthContext).user
  return (user?.isDeveloper || false)
}

const AuthProvider: React.FC<AuthProviderProps> = ({user, children}) => {
  return (
    <AuthContext.Provider value={{user: user}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export function trim(str: string, ch: string): string {
  let start = 0, end = str.length
  while (start < end && str[start] === ch) {
    ++start
  }
  while (end > start && str[end - 1] === ch) {
    --end
  }
  return (start > 0 || end < str.length)? str.substring(start, end): str
}

export function capitalise(str: string): string {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export function humanReadable(str: string): string {
  const words = str.match(/[A-Za-z][a-z]*|[0-9]+/g) || []
  return words.map(capitalise).join(' ')
}

export function nameToInitials(name: string): string | null {
  return name
      .match(/(\b\S)?/g)
      ?.join('')
      .match(/(^\S|\S$)?/g)
      ?.join('')
      .toUpperCase()
    || null
}

export function isEmptyStr(str: string): boolean {
  return str.trim().length === 0
}

export function isPositiveNumber(str: string): boolean {
  return /(^\d+\.\d+$)|(^\d+$)/.test(str)
}

export function isNumber(str: string): boolean {
  return /(^-?\d+\.\d+$)|(^-?\d+$)/.test(str)
}
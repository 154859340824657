import { notifications } from '@mantine/notifications'
import * as _ from 'lodash'
import { chunkArray, isTruthy } from '~/lib/util/misc.util'

export type UUID = string
export type YearMonth = string

export interface EmptyResponse {
}

export interface IdResponse {
  id: UUID
}

export interface BaseListResponse {
  totalCount: number
  pageSize: number
  pageNum: number
  responseCount: number
  data: any[]
}

export function convertQueryToString(query: Record<string, any>, ids?: (UUID | string | number)[]): string {
  const encodedFilters = query? _.map(query, (v, k) => `${k}=${encodeURIComponent(v)}`).join('&'): ''
  const encodedIdList = ids && ids.length? `ids=${ids.join(',')}`: ''
  return [encodedFilters, encodedIdList].filter(isTruthy).join('&')
}

export async function wrappedRequest<T>(request: () => Promise<T>, message?: string): Promise<T> {
  try {
    return await request()
  }
  catch (e) {
    notifications.show({
      color: 'red',
      autoClose: 5000,
      title: 'Communication Error',
      message: message? message: `An error occurred when trying to retrieve the requested data`
    })
    console.log(e)
    throw e
  }
}

export async function chunkedRequest<T extends BaseListResponse, K>(
  requestor: (queryString: string) => Promise<T>,
  query?: Record<string, string | number | undefined>,
  idList?: string[],
  idsParam: string = 'ids',
  chunkLength: number = 50
): Promise<BaseListResponse> {
  const encodedFilters = query? _.map(query, (v, k) => v !== undefined? `${k}=${encodeURIComponent(v)}`: undefined).filter(v => v !== undefined).join('&'): ''
  if (idList && idList.length > 0) {
    const chunks = chunkArray(idList, chunkLength)
    const data: K[] = []
    for (let i = 0; i < chunks.length; i++) {
      const encodedIdList = `${idsParam}=${chunks[i].join(',')}`
      const queryString = [encodedFilters, encodedIdList].filter(isTruthy).join('&')

      const response = await requestor(queryString)
      response.data.map((m: K) => data.push(m))
    }

    return {
      totalCount: data.length,
      pageSize: data.length,
      pageNum: 1,
      responseCount: data.length,
      data: data
    }
  }
  else {
    const queryString = [encodedFilters].filter(isTruthy).join('&')
    return (await requestor(queryString))
  }
}

export const emptyResponse = () => {
  return {
    totalCount: 0,
    pageSize: 0,
    pageNum: 0,
    responseCount: 0,
    data: []
  }
}

export function convertBoolean(filter: undefined | null | string): string {
  return filter === undefined || filter === null || filter === ''? '': (filter === 'true'? 'true': 'false')
}

/**
 * The foundation of this code was taken from jquery-deparam, it was then turned into Typescript
 * and then refactored to clean-up processing and modified to support a[]=value
 */
export function convertSearchParamsToObject(urlsp: URLSearchParams): Record<string, any> {
  const obj: Record<string, any> = {}

  if (urlsp.toString() === '' || urlsp.size === 0) {
    return obj
  }

  const nameValuePairs = urlsp.toString().replace(/\+/g, ' ').split('&')
  nameValuePairs.forEach((nvp: string) => {
    const [origKey, origVal] = nvp.split('=')
    if (origVal === undefined) {
      return
    }

    let key = decodeURIComponent(origKey)
    let keys = parseNestedKeys(key)
    let val = coerceStringToType(decodeURIComponent(origVal))

    /**
     * also supports: val=1&val=2 results in val = [1, 2]
     */
    if (keys.length === 1) {
      if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
        obj[key].push(val)
      }
      else if (Object.hasOwnProperty.call(obj, key)) {
        // val isn't an array, but since a second value has been specified,
        // convert val into an array.
        obj[key] = [obj[key], val]
      }
      else {
        obj[key] = val // val is a scalar.
      }
    }

    if (keys.length > 1) {
      objectDeepPush(obj, keys, val)
    }
  })

  return obj
}

export function parseNestedKeys(key: string): string[] {
  let keys = key.split('][') || []
  let lastIndex = keys.length - 1

  const isNestedOrArray = (/\[/.test(keys[0]) && /\]$/.test(keys[lastIndex]))

  // If the first keys part contains [ and the last ends with ], then []
  // are correctly balanced. This also holds true for a[] types too
  if (isNestedOrArray) {
    // Remove the trailing ] from the last keys part.
    keys[lastIndex] = keys[lastIndex].replace(/\]$/, '')

    // Split first keys part into two parts on the [ and add them back onto
    // the beginning of the keys array.
    keys = (keys.shift() || '')?.split('[').concat(keys)
  }
  return keys
}

export function coerceStringToType(val: string): string | number | boolean | null | undefined {
  const coerceTypes: Record<string, boolean | null> = { 'true': true, 'false': false, 'null': null }

  // number
  if (val && !isNaN(+val) && ((+val + '') === val)) {
    return +val
  }

  if (val === 'undefined') {
    return undefined
  }

  // true, false, null
  if (coerceTypes[val] !== undefined) {
    return coerceTypes[val]
  }

  return val
}

export function objectDeepPush(obj: Record<string, any>, keys: string[], val: any) {
  const lastIndex = keys.length - 1
  let currPtr: Record<string, any> = obj

  for (let i = 0; i <= lastIndex; i++) {
    const currKey = keys[i]
    const isLastKey = i === lastIndex

    if (currKey === '') {
      currPtr.push(val)
    }
    else {
      if (isLastKey) {
        currPtr[currKey] = val
      }
      else {
        const emptyKey = keys[i + 1] === ''
        currPtr[currKey] ||= (emptyKey? []: {})
        currPtr = currPtr[currKey]
      }
    }
  }

  return obj
}
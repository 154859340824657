import React, { CSSProperties } from 'react'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const pageIconStyle = { 'color': '#63bfff', '--fa-primary-color': '#63bfff', '--fa-secondary-color': '#63bfff' } as CSSProperties

const createStyle = (style: CSSProperties | undefined, isPageIcon?: boolean, props?: IconProps) => {
  const customCssProps: CSSProperties = {
    marginTop: props?.mt ?? undefined,
    marginRight: props?.mr ?? undefined,
    marginBottom: props?.mb ?? undefined,
    marginLeft: props?.ml ?? undefined,
    fontSize: props?.fz ?? undefined,
    verticalAlign: props?.va ?? undefined,
    color: props?.color ?? undefined
  }

  return {
    ...customCssProps,
    ...(style || {}),
    ...(!!isPageIcon? pageIconStyle: {})
  }
}

interface IconProps {
  style?: CSSProperties
  className?: string
  primary?: boolean
  type?: 'regular' | 'duotone' | 'light' | 'solid' | 'thin'
  spin?: boolean
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  fz?: number
  va?: number
  color?: string
}

export const IconDashboard = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'grid-2', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCustomer = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'user-tie-hair', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconContract = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'file-contract', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTable = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'table', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconList = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'list', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconUsers = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'users', style: 'regular', family: 'classic' }),
    'duotone': icon({ name: 'users', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSubmenuItem = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'l', style: 'regular' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTicket = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'life-ring', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary)} {...props} />
}

export const IconDev = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'code', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconUserAddReg = () => (
  <FontAwesomeIcon icon={icon({ name: 'user-plus', style: 'regular' })} />
)

export const IconHome = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'house', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconQuestionCircled = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'circle-question', style: 'regular', family: 'classic' }),
    'light': icon({ name: 'circle-question', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDelete = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'trash', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDeleteAlt = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'trash-can', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconClean = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'broom-wide', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPlus = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'plus', style: 'regular', family: 'classic' }),
    'solid': icon({ name: 'plus', style: 'solid', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMinus = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'minus', style: 'regular', family: 'classic' }),
    'solid': icon({ name: 'minus', style: 'solid', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSquare = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'square', style: 'regular', family: 'classic' }),
    'solid': icon({ name: 'square', style: 'solid', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBolt = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'bolt', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMove = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'arrows-up-down-left-right', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconLocationPin = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'location-dot', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTreeExpanded = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'angle-down', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMinusCircled = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'circle-minus', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconChart = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'chart-line', style: 'solid' }),
    'regular': icon({ name: 'chart-line', style: 'regular' }),
    'light': icon({ name: 'chart-line', style: 'light' }),
    'duotone': icon({ name: 'chart-line', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconAudit = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'file-magnifying-glass', style: 'duotone' }),
    'solid': icon({ name: 'file-magnifying-glass', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconEmail = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'envelope', style: 'duotone' }),
    'solid': icon({ name: 'envelope', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDataImport = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'file-import', style: 'duotone' }),
    'solid': icon({ name: 'file-import', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconImportWizard = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'duotone': icon({ name: 'wand-magic-sparkles', style: 'duotone' }),
    'solid': icon({ name: 'wand-magic-sparkles', style: 'solid' }),
    'light': icon({ name: 'wand-magic-sparkles', style: 'light' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconStopProgress = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'octagon-exclamation', style: 'solid' }),
    'regular': icon({ name: 'octagon-exclamation', style: 'regular' }),
    'light': icon({ name: 'octagon-exclamation', style: 'light' }),
    'duotone': icon({ name: 'octagon-exclamation', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconUser = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'user', style: 'solid' }),
    'regular': icon({ name: 'user', style: 'regular' }),
    'light': icon({ name: 'user', style: 'light' }),
    'duotone': icon({ name: 'user', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBilling = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'coins', style: 'solid' }),
    'regular': icon({ name: 'coins', style: 'regular' }),
    'light': icon({ name: 'coins', style: 'light' }),
    'duotone': icon({ name: 'coins', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBill = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'file-invoice-dollar', style: 'regular', family: 'sharp' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBillBreakdown = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'magnifying-glass-dollar', style: 'regular', family: 'classic' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconHelpdesk = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'headset', style: 'solid' }),
    'regular': icon({ name: 'headset', style: 'regular' }),
    'light': icon({ name: 'headset', style: 'light' }),
    'duotone': icon({ name: 'headset', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconIntranet = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'globe', style: 'solid' }),
    'regular': icon({ name: 'globe', style: 'regular' }),
    'light': icon({ name: 'globe', style: 'light' }),
    'duotone': icon({ name: 'globe', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconLogout = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'arrow-right-from-bracket', style: 'solid' }),
    'regular': icon({ name: 'arrow-right-from-bracket', style: 'regular' }),
    'light': icon({ name: 'arrow-right-from-bracket', style: 'light' }),
    'duotone': icon({ name: 'arrow-right-from-bracket', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconReleases = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'rocket-launch', style: 'solid' }),
    'regular': icon({ name: 'rocket-launch', style: 'regular' }),
    'duotone': icon({ name: 'rocket-launch', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSearch = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'magnifying-glass', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSpin = ({ type = 'duotone', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'spinner-third', style: 'regular' }),
    'duotone': icon({ name: 'spinner-third', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBack = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'arrow-left-long', style: 'regular' }),
    'light': icon({ name: 'arrow-left-long', style: 'light' }),
    'thin': icon({ name: 'arrow-left-long', style: 'thin' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPrevious = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'chevron-left', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconNext = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'chevron-right', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconRegenerate = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'rotate', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconReview = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'file-magnifying-glass', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconReviewed = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'file-circle-check', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconExpand = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'arrows-from-line', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCollapse = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'arrows-to-line', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSelected = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'angle-right', style: 'regular' }),
    'solid': icon({ name: 'angle-right', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconView = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'eye', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconClock = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'clock', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconValidBadge = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'badge-check', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCopy = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'copy', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconLinked = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'link', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconUnlink = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'link-slash', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMonth = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'calendar-days', style: 'solid', family: 'sharp' }),
    'duotone': icon({ name: 'calendar-days', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconHistory = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'clock-rotate-left', style: 'solid', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconEmailAddress = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'at', style: 'regular', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconInfoCircled = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'circle-info', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconIdentity = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'address-card', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconJobTitle = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'briefcase', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPhone = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'phone', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconOffice = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'building', style: 'light', family: 'sharp' }),
    'thin': icon({ name: 'building', style: 'thin' }),
    'duotone': icon({ name: 'building', style: 'duotone' }),
    'regular': icon({ name: 'building', style: 'regular' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCampus = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'globe', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconFilter = ({ type = 'light', style, primary, ...props }: IconProps) => {
  const options: any = {
    'light': icon({ name: 'filter', style: 'light', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconSettings = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'gear', style: 'regular', family: 'sharp' }),
    'duotone': icon({ name: 'gear', style: 'duotone', family: 'classic' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTariff = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'plug-circle-bolt', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPermissions = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'key', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDisabledOption = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'circle-xmark', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCross = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'xmark', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTick = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'check', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconValid = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'circle-check', style: 'solid' }),
    'regular': icon({ name: 'circle-check', style: 'regular' }),
    'light': icon({ name: 'circle-check', style: 'light' }),
    'duotone': icon({ name: 'circle-check', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconValidBoxed = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'square-check', style: 'regular', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconInvalid = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'circle-exclamation', style: 'solid' }),
    'regular': icon({ name: 'circle-exclamation', style: 'regular' }),
    'light': icon({ name: 'circle-exclamation', style: 'light' }),
    'duotone': icon({ name: 'circle-exclamation', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconInvalidTriangle = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'triangle-exclamation', style: 'regular', family: 'sharp' }),
    'light': icon({ name: 'triangle-exclamation', style: 'light', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconWarning = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'triangle-exclamation', style: 'regular' }),
    'light': icon({ name: 'triangle-exclamation', style: 'light' }),
    'solid': icon({ name: 'triangle-exclamation', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPending = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'hourglass-clock', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconIncrease = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'arrow-up-right', style: 'solid' }),
    'regular': icon({ name: 'arrow-up-right', style: 'regular' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDecrease = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'arrow-down-right', style: 'solid' }),
    'regular': icon({ name: 'arrow-down-right', style: 'regular' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconBillingNoChange = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'arrow-right', style: 'solid' }),
    'regular': icon({ name: 'arrow-right', style: 'regular' }),
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconElectricalSupply = ({ size = 25, ...props }: IconProps & { size?: number }) => {
  const style = { ...{ marginTop: -2, verticalAlign: '-3px', color: 'inherit', height: size, width: size }, ...props.style }
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M160 15.78l-37.4 56.07-8 1.5-.6-.13-.1.24-98.05 18.25L39 104.6V160h18v-45.3l68.7 38.3h12.4l40.3 94H96v18h23v39h18v-39h42.8l-64 208h82.5L256 314.3 313.7 473h82.5l-64.1-208H375v39h18v-39h23v-18h-82.4l40.3-94h12.4l68.7-38.3V160h18v-55.4l23.2-12.89-98.1-18.25-.1-.24-.6.13-8-1.5L352 15.78 315.2 71H196.8L160 15.78zm0 32.44L175.2 71h-30.4L160 48.22zm192 0L367.2 71h-30.4L352 48.22zM183.2 89h145.6L256 105.6 183.2 89zm-69 2.73l101.2 23.07-86.1 19.6-61.15-34.1 46.05-8.57zm283.6 0l46 8.57-61.1 34.1-86.1-19.6 101.2-23.07zM256 124.1l47.9 10.9h-95.8l47.9-10.9zM165.8 153h43.1l-15.1 28.1-28-28.1zm63.6 0h53.2l22.3 41.3-48.9 49-48.9-49 22.3-41.3zm73.7 0h43.1l-28 28.1-15.1-28.1zm-132.4 30.4l63.6 63.6h-36.4l-27.2-63.6zm170.6 0L314.1 247h-36.4l63.6-63.6zM203.4 265h51.4l-31.3 85.9-20.1-85.9zm53.8 0h51.4l-20.1 85.9-31.3-85.9zm-66.4 25.4l19.8 84.6h-45.8l26-84.6zm130.4 0l26 84.6h-45.8l19.8-84.6zM159.3 393h48.9l-22.5 62h-45.5l19.1-62zm144.5 0h48.9l19.1 62h-45.5l-22.5-62z"></path>
    </svg>
  )
}

export const IconMeter = ({ size = 25, ...props }: IconProps & { size?: number }) => {
  const style = { ...{ verticalAlign: '-5px', color: 'inherit', height: size, width: size }, ...props.style }
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={style} xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M21 11a9 9 0 00-18 0c0 3.92 2.51 7.24 6 8.48V22h2v-2.06a8.262 8.262 0 002 0V22h2v-2.52c3.49-1.24 6-4.56 6-8.48zm-9 7c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"></path>
      <path d="M8 7h8v2H8zM12.75 10l-3 3L11 14.25 9.75 15.5l1.5 1.5 3-3L13 12.75l1.25-1.25z"></path>
    </svg>
  )
}

export const IconMeterHierarchy = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'share-nodes', style: 'solid' }),
    'regular': icon({ name: 'share-nodes', style: 'regular' }),
    'light': icon({ name: 'share-nodes', style: 'light' }),
    'duotone': icon({ name: 'share-nodes', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMeterUsageTypeMissing = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'circle-dashed', style: 'solid' }),
    'regular': icon({ name: 'circle-dashed', style: 'regular' }),
    'light': icon({ name: 'circle-dashed', style: 'light' }),
    'duotone': icon({ name: 'circle-dashed', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCircle = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'circle', style: 'solid' }),
    'regular': icon({ name: 'circle', style: 'regular' }),
    'light': icon({ name: 'circle', style: 'light' }),
    'duotone': icon({ name: 'circle', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconMeterUsageType = IconCircle

export const IconPercent = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'percent', style: 'solid' }),
    'regular': icon({ name: 'percent', style: 'regular' }),
    'light': icon({ name: 'percent', style: 'light' }),
    'duotone': icon({ name: 'percent', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconAlertSuppression = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'bell-slash', style: 'solid' }),
    'regular': icon({ name: 'bell-slash', style: 'regular' }),
    'light': icon({ name: 'bell-slash', style: 'light' }),
    'duotone': icon({ name: 'bell-slash', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconEdit = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'pen-to-square', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconAutoCalculate = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'calculator', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCalculation = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'calculator-simple', style: 'regular' }),
    'duotone': icon({ name: 'calculator-simple', style: 'duotone' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconActualValue = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'crosshairs-simple', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconTestValue = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'vial', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconPencil = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'pencil', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconComment = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'comment', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconCommentAdd = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'comment-plus', style: 'solid' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconResolve = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'handshake', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconDataDetails = ({ type = 'regular', style, primary, ...props }: IconProps) => {
  const options: any = {
    'regular': icon({ name: 'memo-circle-info', style: 'regular' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}

export const IconAlertDuo = ({ primary }: { primary?: boolean }) => (
  <FontAwesomeIcon icon={icon({ name: 'light-emergency', style: 'duotone' })} {...{ style: primary? pageIconStyle: undefined }} />
)

export const IconAlertSld = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'light-emergency', style: 'solid' })} {...props} />
)

export const IconAlertReg = ({ primary }: { primary?: boolean }) => (
  <FontAwesomeIcon icon={icon({ name: 'light-emergency', style: 'solid' })} {...{ style: primary? { color: '#63bfff' }: undefined }} />
)

export const IconAlertAddReg = () => (
  <FontAwesomeIcon icon={icon({ name: 'bell-plus', style: 'regular' })} />
)

export const IconMeterReg = () => (
  <FontAwesomeIcon icon={icon({ name: 'meter-bolt', style: 'regular' })} />
)

export const IconExtLinkReg = () => (
  <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-from-square', style: 'regular' })} />
)

export const IconEditReg = () => (
  <FontAwesomeIcon icon={icon({ name: 'pen-to-square', style: 'regular' })} />
)

export const IconMeterGroupLte = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'object-group', style: 'light', family: 'sharp' })} {...props} />
)

export const IconAlertResolvedDuo = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'handshake', style: 'duotone' })} {...props} />
)

export const IconClockReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'clock', style: 'regular' })} {...props} />
)

export const IconAlertAssignReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'user-check', style: 'regular' })} {...props} />
)

export const IconAlertStatusSld = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'signal-bars', style: 'solid' })} {...props} />
)

export const IconAlertPriorityReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation', style: 'regular' })} {...props} />
)

export const IconAlertClosedReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'square-check', style: 'regular' })} {...props} />
)

export const IconAlertEventReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'circle-small', style: 'regular' })} {...props} />
)

export const IconAlertCommentReg = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'comment', style: 'regular' })} {...props} />
)

export const IconAlertQuoteSld = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'quote-left', style: 'solid' })} {...props} />
)

export const IconHierarchy = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'list-tree', style: 'light', family: 'sharp' })} {...props} />
)

export const IconContextMenu = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'bars', style: 'regular' })} {...props} />
)

export const IconAdd = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} {...props} />
)

export const IconExportCSV = (props: IconProps) => (
  <FontAwesomeIcon icon={icon({ name: 'file-csv', style: 'regular' })} {...props} />
)

export const IconPDF = ({ type = 'solid', style, primary, ...props }: IconProps) => {
  const options: any = {
    'solid': icon({ name: 'file-pdf', style: 'solid', family: 'sharp' })
  }
  return <FontAwesomeIcon icon={options[type]} style={createStyle(style, primary, props)} {...props} />
}
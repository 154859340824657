import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbBilling = (match: Match) => ({ title: 'Billing', key: 'billing' })
const crumbBillingList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'billing-list' })
const crumbBillingOverview = (match: Match) => ({ title: <Link to={match.pathname}>Overview</Link>, key: 'billing-overview' })

const billingRoutes = [
  {
    path: 'billing',
    handle: { crumb: crumbBilling },
    lazy: async () => import('~/app/staff/views/billing/BillingIndexView'),
    children: [
      {
        index: true,
        path: 'list',
        handle: { crumb: crumbBillingList }
      },
      {
        path: 'overview',
        handle: { crumb: crumbBillingOverview }
      }
    ]
  }
]

export default billingRoutes
import authConfig from '../../config/auth.config'

/**
 * Attaches a given access token to an MS Graph API call.
 * Returns information about the user
 */
export async function callMsGraph<T>(accessToken: string, transformer: (arg: Record<string, any>) => T ): Promise<T | null> {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers
  }

  // todo: change to axios
  try {
    const response = await fetch(authConfig.buildGraphConfig().graphMeEndpoint, options)
    const body = await response.json()

    /**
     * This will return an object with roughly the following:
     * "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#users/$entity"
     * businessPhones: Array []
     * displayName: "Dave Goodchild"
     * givenName: "Dave"
     * id: "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4"
     * jobTitle: "Data Strategy Lead"
     * mail: "Dave.Goodchild@arkdatacentres.co.uk"
     * mobilePhone: "+44 7421 077976"
     * officeLocation: null
     * preferredLanguage: null
     * surname: "Goodchild"
     * userPrincipalName: "dave.goodchild@arkdatacentres.co.uk"
     */
    return transformer(body)
  }
  catch (e) {
    console.log(e)
    return null
  }
}

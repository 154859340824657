import { defaultVariantColorsResolver, parseThemeColor, VariantColorsResolver } from '@mantine/core'

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme
  })

  if (input.variant === 'nice') {
    return {
      ...defaultResolvedColors,
      background: 'var(--mantine-color-white)',
      hover: 'var(--mantine-color-white)',
      border: `1px solid var(--mantine-color-grey-3)`,
      color: 'var(--mantine-color-dark-5)',
      hoverColor: parsedColor.value
    }
  }

  if (input.variant === 'link') {
    return {
      ...defaultResolvedColors,
      background: 'transparent',
      hover: 'transparent',
      border: 'none',
      color: '#1677ff',
      hoverColor: '#69b1ff'
    }
  }

  return defaultResolvedColors
}

export default variantColorResolver
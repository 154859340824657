import instance from '~/instance'
import authConfig from '~/config/auth.config'
import { InteractionRequiredAuthError } from '@azure/msal-common'

export async function acquireAccessToken(): Promise<string | boolean | null> {
  const msalInstance = instance.msalInstance()
  const activeAccount = msalInstance.getActiveAccount()
  const accounts = msalInstance.getAllAccounts()

  const authRequest = authConfig.buildAuthRequest()

  /*
  if (!activeAccount && accounts.length === 0) {
    await msalInstance.acquireTokenRedirect({
      scopes: authRequest.scopes
    })
    return null
  }
  */

  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: authRequest.scopes,
      account: activeAccount || accounts[0]
    })
    return response? response.accessToken: null
  }
  catch (e) {
    // this is just satisfying the return type, but in actual fact the acquireTokenRedirect
    // will redirect the user and so nothing will actually execute after this call
    // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-error-handling-js
    // https://learn.microsoft.com/en-us/azure/active-directory/develop/reference-error-codes
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4104


    /*
    //console.log(e)
    //if (e instanceof InteractionRequiredAuthError) {
      try {
        //if (msalInstance.inProgress === InteractionStatus.None) {
        await msalInstance.acquireTokenRedirect({
          scopes: authRequest.scopes,
          loginHint: (activeAccount || accounts[0])?.username
        })
        //}
      }
      catch (e) {
        console.log(e)
        return null
      }
    //}
    */
    return false
  }
}
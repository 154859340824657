import ReactDOM, { Root } from 'react-dom/client'
import { QueryClient } from '@tanstack/react-query'
import { PublicClientApplication } from '@azure/msal-browser'
import authConfig from './config/auth.config'

class AppInstance {

  private root?: Root
  private msal?: PublicClientApplication
  private query?: QueryClient

  createMsal(config: { clientId: string, tenantId: string }) {
    authConfig.setClientId(config.clientId)
    authConfig.setTenantId(config.tenantId)
    this.msal = new PublicClientApplication(authConfig.buildMsalConfig())
    return this.msal.initialize()
  }

  rootInstance() {
    if (!this.root) {
      this.root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    }
    return this.root
  }

  msalInstance() {
    if (!this.msal) {
      throw new Error('MSAL has not been configured!')
    }
    return this.msal
  }

  queryInstance() {
    if (!this.query) {
      this.query = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      })
    }
    return this.query
  }
}

const instance = new AppInstance()
export default instance
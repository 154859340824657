import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'
import { getCrumbFromPath } from '~/lib/util/navigation.util'

const crumbUser = (match: Match) => ({ title: 'Users', key: 'user' })
const crumbUserList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'user-list' })
const crumbUserTab = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname)}</Link>, key: 'user-inspect' })

const userRoutes = [
  {
    path: 'user/:tab',
    handle: { crumb: crumbUser },
    lazy: async () => import('~/app/staff/views/user/UserIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbUserList }
      }
    ]
  },
  {
    path: 'user/view/:userId',
    handle: { crumb: crumbUser },
    lazy: async () => import('~/app/staff/views/user/UserInspectView'),
    children: [
      {
        path: ':tab',
        handle: { crumb: crumbUserTab }
      },
    ]
  }
]

export default userRoutes
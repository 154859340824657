import http from '../../core/http'
import { wrappedRequest } from '~/lib/util/request.util'
import { UserAuthConfigResponse, UserProfileResponse } from '~/module/user/user.interface'

export class PublicUserService {

  async getAuthConfig(): Promise<UserAuthConfigResponse> {
    return wrappedRequest<UserAuthConfigResponse>(
      async () => {
        const response = await http.get<UserAuthConfigResponse>(`/user/config`)
        return response.data
      },
      'Error retrieving user auth config'
    )
  }

  async getProfileAndUpdateAuthCookie(muteLastSeen: boolean = false): Promise<UserProfileResponse> {
    return wrappedRequest<UserProfileResponse>(
      async () => {
        const response = await http.get<UserProfileResponse>(`/user/profile${muteLastSeen? '?muted=true': ''}`)
        return response.data
      },
      `Error retrieving user profile`
    )
  }
}

export const publicUserService = new PublicUserService()

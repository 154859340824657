import React from 'react'
import { Container, MantineProvider, Title } from '@mantine/core'
import Fullscreen from '~/app/common/layout/Fullscreen'
import { Text } from '~/lib/component/Text'

const Authorising: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {

  return (
    <MantineProvider>
      <Fullscreen style={{ backgroundColor: '#f2f2f2' }}>
        <Container className="authorising">
          <Title className="title">Authorising</Title>

          <Text c="dimmed" size="lg" className="description" mt="xl">
            Authorisation required, and is now in progress.
          </Text>
          <Text c="dimmed" size="lg" className="description" mt="xs">
            Please wait.
          </Text>
        </Container>

        {props.children}
      </Fullscreen>
    </MantineProvider>
  )
}

export default Authorising
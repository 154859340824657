import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'

const crumbData = (match: Match) => ({ title: 'Data Import', key: 'dataimport' })
const crumbDataInspect = (match: Match) => ({ title: <Link to={match.pathname}>Inspect</Link>, key: 'dataimport-inspect' })
const crumbDataList = (match: Match) => ({ title: <Link to={match.pathname}>List</Link>, key: 'dataimport-list' })

const dataRoutes = [
  {
    path: 'data/import/:tab?/:importId?',
    handle: { crumb: crumbData },
    lazy: async () => import('~/app/staff/views/data/import/ImportIndexView'),
    children: [
      {
        index: true,
        handle: { crumb: crumbDataList }
      }
    ]
  },
  {
    path: 'data/import/view/:importId',
    handle: { crumb: crumbDataInspect },
    lazy: async () => import('~/app/staff/views/user/UserInspectView')
  }
]

export default dataRoutes
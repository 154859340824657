import React, { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'

const Main = styled.main`
  flex: 1;
  width: 100vw;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0;
  margin: 0;
`

interface Props extends React.PropsWithChildren {
  style?: CSSProperties
  children?: ReactNode
}

const Fullscreen: React.FC<Props> = (props: Props) => {
  return (<Main style={props.style}>
    {props.children}
  </Main>)
}

export default Fullscreen
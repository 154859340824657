import { Match } from '~/config/router'

const crumbDev = (match: Match) => ({ title: 'Development', key: 'development' })

const devRoutes = [
  {
    path: 'development',
    children: [
      {
        path: 'ui-components/:tab',
        handle: { crumb: crumbDev },
        lazy: async () => import('~/app/staff/views/development/ui-components/UIComponentsIndexView')
      },
      {
        path: 'global-error',
        lazy: async () => import('~/app/common/GlobalErrorPage')
      },
      {
        path: 'staff',
        children: [
          {
            path: 'error',
            lazy: async () => import('~/app/staff/error/Error')
          },
          {
            path: 'error-auth',
            lazy: async () => import('~/app/staff/error/ErrorAuth')
          },
          {
            path: 'error-404',
            lazy: async () => import('~/app/staff/error/Error404')
          }
        ]
      }
    ]
  }
]

export default devRoutes
import axios, { AxiosError } from 'axios'
import { acquireAccessToken } from '~/core/accessToken'
import _ from 'lodash'

let accessToken: string | null = null

// https://axios-http.com/docs/req_config
const instance = axios.create({
  baseURL: window.location.origin + '/api',
  headers: {
    'Content-type': 'application/json'
  }
})

instance.interceptors.request.use(
  async (config: any) => {
    //const accessToken = await acquireAccessToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  }
)

instance.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (error.response?.status !== 401) {
      console.log(`[HTTP] Failed with a non-401 response code: ${error.response?.status}`)
      return Promise.reject(error)
    }
    else {
      console.log(`[HTTP] Failed with a 401. Attempting to auto-recover.`)
    }

    const newAccessToken = await acquireAccessToken()
    if (typeof newAccessToken !== 'string') {
      console.log('[HTTP] Could not obtain a new access token.')
      return Promise.reject(error)
    }
    else {
      console.log(`[HTTP] New access token acquired.`)
      accessToken = newAccessToken
    }

    const origReq = _.cloneDeep(error.config)
    delete origReq?.headers['Authorization']

    return axios.request(origReq!)
  }
)

export function configureAccessToken(token: string | null) {
  accessToken = token
}

export default instance
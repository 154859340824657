import React from 'react'
import { Link } from 'react-router-dom'
import { Match } from '~/config/router'
import { getCrumbFromPath } from '~/lib/util/navigation.util'

const crumbPueConfiguration = (match: Match) => ({ title: 'PUE Values', key: 'pue-configuration' })
const crumbPueConfigurationTab = (match: Match) => ({ title: <Link to={match.pathname}>{getCrumbFromPath(match.pathname, 'Details')}</Link>, key: 'pue-configuration-tab' })

const pueValueRoutes = [
  {
    path: 'pue-value',
    handle: { crumb: crumbPueConfiguration },
    lazy: async () => import('~/app/staff/views/pue-value/PueValueIndexView'),
    children: [
      {
        path: ':tab',
        index: true,
        handle: { crumb: crumbPueConfigurationTab }
      }
    ]
  }
]

export default pueValueRoutes
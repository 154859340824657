import { Location } from 'react-router-dom'
import { capitalise, trim } from '~/lib/util/string.util'

export const browserUrl = window.location.protocol + '//' + window.location.host

export function concatUrl(...paths: string[]): string {
  return paths.map(path => trim(path, '/')).filter(path => path !== '').join('/')
}

export function locationToStaffPath(location: Location): string {
  return [location.pathname, location.search, location.hash].join('').replace(/^\/staff\//, '')
}

export function createStaffPath(...paths: string[]) {
  return '/' + concatUrl('staff', ...paths)
}

export function createStaffUrl(...paths: string[]) {
  return browserUrl + createStaffPath(...paths)
}

export function parseUrl(url: string): URL {
  return new URL(url)
}

export function parsePathname(pathname: string): string[] {
  return trim(pathname, '/').split('/')
}

export function getCrumbFromPath(path: string, fallback: string = '') {
  const tokens = path.split('/')
  const last = tokens.filter(t => /^[a-z-]+$/.test(t)).pop()
  return last ? capitalise(last) : fallback
}
import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { createStaffPath } from '~/lib/util/navigation.util'

const Error404 = () => {
  // todo: SENTRY
  return (
    <Result
      style={{marginTop: 80}}
      status="404"
      title="Page Not Found"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Link to={createStaffPath()} type="primary">Go back to the dashboard</Link>}
    />
  )
}

export const Component = Error404
export default Error404
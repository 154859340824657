import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { EventType, InteractionStatus } from '@azure/msal-browser'
import authConfig from '~/config/auth.config'
import { InteractionRequiredAuthError } from '@azure/msal-common'
import Authorising from '~/app/staff/auth/Authorising'
import { Navigate } from 'react-router-dom'
import { User } from '~/module/user/user.interface'
import { publicUserService } from '~/module/user/public-user.service'
import { callMsGraph } from '~/lib/microsoft/graph.client'
import { configureAccessToken } from '~/core/http'
import { validateEmailsMatch } from '~/lib/util/validation.util'
import AuthProvider from '~/app/staff/auth/AuthProvider'

interface UserValidation {
  user?: any
  valid?: boolean
  errorType?: string
}

async function validateAuthentication(instance: any, account: any, accessToken: string): Promise<UserValidation> {
  console.log('[Authentication] validateAuthentication')

  try {
    configureAccessToken(accessToken)

    const responseProfileApi = await publicUserService.getProfileAndUpdateAuthCookie(true)
    console.log('Profile request response data:', responseProfileApi)

    const responseAuthToken = await instance.acquireTokenSilent({
      scopes: ['User.Read'],
      account: account
    })
    console.log('Auth token request response data:', responseAuthToken)

    if (responseAuthToken) {
      const user = await callMsGraph<User>(responseAuthToken.accessToken, (o) => {
        return {
          id: responseProfileApi.id,
          msId: o.id,
          displayName: o.displayName,
          givenName: o.givenName,
          surname: o.surname,
          jobTitle: o.jobTitle,
          email: (o.mail || o.userPrincipalName || '').replace(/^_ark_/i, '').toLowerCase(),
          mobilePhone: o.mobilePhone,
          officeLocation: o.officeLocation,
          preferredLanguage: o.preferredLanguage,
          isSuper: responseProfileApi.isSuper,
          isDeveloper: responseProfileApi.isDeveloper,
          permissions: responseProfileApi.permissions
        } as User
      })

      console.log('Response was: ', user)
      if (user) {
        return { user, valid: validateEmailsMatch(responseProfileApi.email, user.email) }
      }
    }
  }
  catch (e) {
    console.log('Profile request error: ', e)
    return { errorType: 'general' }
  }

  return { errorType: 'unknown' }
}

/**
 * NEVER EVER DELETE THIS COMMENT.
 *
 * This whole this is a bag of shit. There are dozens of different documentation pages and code samples
 * all with differing and conflicting information.
 *
 * The basic flow-chart for the login process is flawed, it says to use ssoSilent when there are no
 * accounts, yet I've never seen it work with zero accounts, it will always need a fallback interactive
 * action.
 *
 * msalInstance.getActiveAccount()
 * This will only return a non-null value if somewhere actually calls the msalInstance.setActiveAccount method
 *
 * Flowchart:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/images/msaljs-boot-flow.png
 *
 * MSAL Events (Browser and React):
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 *
 * Prompt Behaviours:
 * https://learn.microsoft.com/en-us/entra/identity-platform/msal-js-prompt-behavior
 *
 * Redirect URI that must be configured in the App Registration: /auth/sso/ms
 *
 * The handleRedirectResponse will receive the following type of object:
 * {
 *   "authority": "https://login.microsoftonline.com/c2465563-c657-4dfe-9189-91a748ac43b6/",
 *   "uniqueId": "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4",
 *   "tenantId": "c2465563-c657-4dfe-9189-91a748ac43b6",
 *   "scopes": [
 *     "a9ff575e-7f29-4ba7-a573-e0bb6504efad/User.Read",
 *     "a9ff575e-7f29-4ba7-a573-e0bb6504efad/.default"
 *   ],
 *   "account": {
 *     "homeAccountId": "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4.c2465563-c657-4dfe-9189-91a748ac43b6",
 *     "environment": "login.windows.net",
 *     "tenantId": "c2465563-c657-4dfe-9189-91a748ac43b6",
 *     "username": "dave.goodchild@arkdatacentres.co.uk",
 *     "localAccountId": "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4",
 *     "name": "Dave Goodchild",
 *     "idTokenClaims": {
 *       "aud": "a9ff575e-7f29-4ba7-a573-e0bb6504efad",
 *       "iss": "https://login.microsoftonline.com/c2465563-c657-4dfe-9189-91a748ac43b6/v2.0",
 *       "iat": 1700646195,
 *       "nbf": 1700646195,
 *       "exp": 1700650095,
 *       "name": "Dave Goodchild",
 *       "nonce": "d91b2eba-8e6c-46a2-8cf5-7ecb1cf668a0",
 *       "oid": "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4",
 *       "preferred_username": "dave.goodchild@arkdatacentres.co.uk",
 *       "rh": "0.ASEAY1VGwlfG_k2RiZGnSKxDtl5X_6kpf6dLpXPgu2UE760hAFI.",
 *       "sub": "_yEQEF2DRyr3xffZU4nzagGA2j8WBSNA0Ou7351FFRE",
 *       "tid": "c2465563-c657-4dfe-9189-91a748ac43b6",
 *       "uti": "AdyHA7SX7ES9li9yuUvUAA",
 *       "ver": "2.0"
 *     },
 *     "authorityType": "MSSTS"
 *   },
 *   "idToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiJhOWZmNTc1ZS03ZjI5LTRiYTctYTU3My1lMGJiNjUwNGVmYWQiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYzI0NjU1NjMtYzY1Ny00ZGZlLTkxODktOTFhNzQ4YWM0M2I2L3YyLjAiLCJpYXQiOjE3MDA2NDYxOTUsIm5iZiI6MTcwMDY0NjE5NSwiZXhwIjoxNzAwNjUwMDk1LCJuYW1lIjoiRGF2ZSBHb29kY2hpbGQiLCJub25jZSI6ImQ5MWIyZWJhLThlNmMtNDZhMi04Y2Y1LTdlY2IxY2Y2NjhhMCIsIm9pZCI6ImU0ZjY1YWQ2LTBmZTItNGRmYy04YmJhLTQ1ZjczYjJhMjNkNCIsInByZWZlcnJlZF91c2VybmFtZSI6ImRhdmUuZ29vZGNoaWxkQGFya2RhdGFjZW50cmVzLmNvLnVrIiwicmgiOiIwLkFTRUFZMVZHd2xmR19rMlJpWkduU0t4RHRsNVhfNmtwZjZkTHBYUGd1MlVFNzYwaEFGSS4iLCJzdWIiOiJfeUVRRUYyRFJ5cjN4ZmZaVTRuemFnR0EyajhXQlNOQTBPdTczNTFGRlJFIiwidGlkIjoiYzI0NjU1NjMtYzY1Ny00ZGZlLTkxODktOTFhNzQ4YWM0M2I2IiwidXRpIjoiQWR5SEE3U1g3RVM5bGk5eXVVdlVBQSIsInZlciI6IjIuMCJ9.a449OSE6BTj7Po1IluCxOD_kRp3vdnf_mbvdJsVTiulTipdaUBfJWELz-4Xsd9Vmht7mCmY-wUNR3dodc2AY6vQXbEmjxoyR_OpiEQMcdJHoJJvezD2h3IOZuuamlOQ9zkmC9alwD0z8HjdjC2iodQnhkFkq7lBm-sgPYJ-A75iGNdAENvNf3NhfecaKBPvMrBSgvMfIT78FcE5cAQJFonnW4V7tGPBaVQnbWEnWRXW7dJ62DDgGByCg3R5AO9RJhR1i5j-BO5vT93TS0AMNvQFr0XJqCgtkpJCHoWq6zgNFNjyCvO6I_K0zA6S-31L5Z4pYIKvQKg6MZMplyvFj7A",
 *   "idTokenClaims": {
 *     "aud": "a9ff575e-7f29-4ba7-a573-e0bb6504efad",
 *     "iss": "https://login.microsoftonline.com/c2465563-c657-4dfe-9189-91a748ac43b6/v2.0",
 *     "iat": 1700646195,
 *     "nbf": 1700646195,
 *     "exp": 1700650095,
 *     "name": "Dave Goodchild",
 *     "nonce": "d91b2eba-8e6c-46a2-8cf5-7ecb1cf668a0",
 *     "oid": "e4f65ad6-0fe2-4dfc-8bba-45f73b2a23d4",
 *     "preferred_username": "dave.goodchild@arkdatacentres.co.uk",
 *     "rh": "0.ASEAY1VGwlfG_k2RiZGnSKxDtl5X_6kpf6dLpXPgu2UE760hAFI.",
 *     "sub": "_yEQEF2DRyr3xffZU4nzagGA2j8WBSNA0Ou7351FFRE",
 *     "tid": "c2465563-c657-4dfe-9189-91a748ac43b6",
 *     "uti": "AdyHA7SX7ES9li9yuUvUAA",
 *     "ver": "2.0"
 *   },
 *   "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiJhOWZmNTc1ZS03ZjI5LTRiYTctYTU3My1lMGJiNjUwNGVmYWQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9jMjQ2NTU2My1jNjU3LTRkZmUtOTE4OS05MWE3NDhhYzQzYjYvIiwiaWF0IjoxNzAwNjQ2MTk1LCJuYmYiOjE3MDA2NDYxOTUsImV4cCI6MTcwMDY1MDk5MiwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhWQUFBQTdEc3VEY0JENG56b3FuSHc1R0xwRmxkL2o5WXlWNURYSjdDNXA4ditDamxLRlhYSGtUWDYrUTJ2M0FBaUdKeFlMYTlkc2J4ZnREN1lJUExQQ3VydnU3M2VvQ09BcVNwanBUWm45ajNsWExzPSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiJhOWZmNTc1ZS03ZjI5LTRiYTctYTU3My1lMGJiNjUwNGVmYWQiLCJhcHBpZGFjciI6IjAiLCJmYW1pbHlfbmFtZSI6Ikdvb2RjaGlsZCIsImdpdmVuX25hbWUiOiJEYXZlIiwiaXBhZGRyIjoiMTM5LjQ3LjEwOS4yMTAiLCJuYW1lIjoiRGF2ZSBHb29kY2hpbGQiLCJvaWQiOiJlNGY2NWFkNi0wZmUyLTRkZmMtOGJiYS00NWY3M2IyYTIzZDQiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtNDA2MzIwMjgyMC0xMjc3OTkxNDA1LTM4NTk2NTc4NTUtMjA2MTciLCJyaCI6IjAuQVNFQVkxVkd3bGZHX2syUmlaR25TS3hEdGw1WF82a3BmNmRMcFhQZ3UyVUU3NjBoQUZJLiIsInNjcCI6IlVzZXIuUmVhZCIsInN1YiI6Il95RVFFRjJEUnlyM3hmZlpVNG56YWdHQTJqOFdCU05BME91NzM1MUZGUkUiLCJ0aWQiOiJjMjQ2NTU2My1jNjU3LTRkZmUtOTE4OS05MWE3NDhhYzQzYjYiLCJ1bmlxdWVfbmFtZSI6ImRhdmUuZ29vZGNoaWxkQGFya2RhdGFjZW50cmVzLmNvLnVrIiwidXBuIjoiZGF2ZS5nb29kY2hpbGRAYXJrZGF0YWNlbnRyZXMuY28udWsiLCJ1dGkiOiJBZHlIQTdTWDdFUzlsaTl5dVV2VUFBIiwidmVyIjoiMS4wIn0.ZeSuSaKn7TDJ84WsUP4_WHDAp8jm2Anigt2ngiCNe6n-bnoEWdawfc2Fcroec3Jp6hXYpYxlb9iwh6Ex4gOOC1SSqfnpG9EhdmD6WmffZ-Y4ImvbFGu9Yv1ARVWhdp9_aliWaH5i-mYmdac2KwnSpuzdDK_Y6HWAwoyDna1HzLyrRtgmVAJVm48zaX8wPuvhmXDF1z4_mgBW08zrvXV8JNTS77QColt3f7QepPncMdYEVzljCy32vS6AlU60rJ50qj8ppuTaHyoB8KEboZCnghk6Lq59vnAt55C2Kr0cBBCiJadEYYb9H9O1lLiMys7h-racsMVE30O8B6UVNN749A",
 *   "fromCache": false,
 *   "expiresOn": "2023-11-22T11:03:12.000Z",
 *   "extExpiresOn": "2023-11-22T12:18:08.000Z",
 *   "correlationId": "74515da0-b616-4522-aa12-fbf754f130c5",
 *   "requestId": "0387dc01-97b4-44ec-bd96-2f72b94bd400",
 *   "familyId": "",
 *   "tokenType": "Bearer",
 *   "state": "",
 *   "cloudGraphHostName": "",
 *   "msGraphHost": "",
 *   "fromNativeBroker": false
 * }
 *
 */
const Authentication: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal()
  const [msalAuthError, setMsalAuthError] = useState(false)

  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [isProfileRequired, setIsProfileRequired] = useState(false)
  const [isProfileLoaded, setIsProfileLoaded] = useState(false)
  const [redirectUri, setRedirectUri] = useState<string | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const accIndex = 0

  function getAccount() {
    return instance.getActiveAccount() || accounts[accIndex]
  }

  useEffect(() => {
    (async () => {
      console.log('[Authentication] Triggered')

      /*
      if (accounts.length > 0) {
        try {
          //const response = await instance.handleRedirectPromise()
          console.log('handleRedirectResponse')
          //console.log(response)
          // clear the client state_not_found
        }
        catch (err) {
          //ClientAuthError: state_not_found: State not found: Cached State
          //ClientAuthError: endpoints_resolution_error: Endpoints cannot be resolved
          console.log('handleRedirectResponse Error:')
          console.log(err)
          alert('handleRedirectPromise error')
        }
      }
      */

      if (inProgress === InteractionStatus.None) {
        console.log('[Authentication] Interaction Status is None')

        if (msalAuthError) {
          console.log('[Authentication] MSAL Auth Error')
          setMsalAuthError(false)
          await instance.loginRedirect({ ...authConfig.buildAuthRequest(), prompt: 'select_account' })
        }
        else if (accounts.length === 0) {
          console.log('[Authentication] SSO Silent (No Accounts Found)')
          try {
            await instance.ssoSilent({ ...authConfig.buildAuthRequest() })
          }
          catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
              !msalAuthError && setMsalAuthError(true)
            }
          }
        }
        else if (accounts.length > 0) {
          console.log('[Authentication] Acquire Token (Accounts Found)')
          if (!instance.getActiveAccount()) {
            instance.setActiveAccount(accounts[accIndex])
          }

          try {
            const resp = await instance.acquireTokenSilent({ ...authConfig.buildAuthRequest(), account: getAccount(), forceRefresh: true })
            setAccessToken(resp.accessToken)
            setIsProfileRequired(true)
          }
          catch (e) {
            instance.loginRedirect({ ...authConfig.buildAuthRequest(), account: getAccount(), prompt: 'select_account' })
            //InteractionRequiredAuthError: login_required: AADSTS50058: A silent sign-in request was sent but no user is signed in.
            //Uncaught (in promise) BrowserAuthError: interaction_in_progress: Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API.   For more visit: aka.ms/msaljs/browser-errors
          }

          const thirtyMinutes = 1000 * (60 * 30)
          setInterval(() => {
            instance.acquireTokenSilent({ ...authConfig.buildAuthRequest(), account: getAccount(), forceRefresh: true })
              .then(resp => {
                console.log('re-requesting access token')
                setAccessToken(resp.accessToken)
              })
              .catch(err => {
                instance.loginRedirect({ ...authConfig.buildAuthRequest(), account: getAccount(), prompt: 'select_account' })
              })
          }, thirtyMinutes)
        }
        else {
          console.log('[Authentication] Unhandled case')
        }
      }
      else {
        console.log(`[Authentication] Unhandled case while there is an interaction (${inProgress})`)
      }
    })()
  }, [inProgress, accounts, msalAuthError])

  useEffect(() => {
    (async () => {
      if (isProfileRequired && accessToken) {
        const response = await validateAuthentication(instance, getAccount(), accessToken)
        if (response.errorType) {
          setRedirectUri(`/staff/authentication/error?${response.errorType}`)
        }
        else if (!response.valid) {
          setRedirectUri(`/staff/authentication/error?nomatch`)
        }
        else {
          setIsProfileLoaded(true)
          setUser(response.user)
        }
      }
    })()
  }, [isProfileRequired, accessToken])

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      // Uncaught InteractionRequiredAuthError: login_required: AADSTS50058: ...
      if (
        message.eventType === EventType.SSO_SILENT_FAILURE ||
        message.eventType === EventType.LOGIN_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
      ) {
        instance.loginRedirect({ ...authConfig.buildAuthRequest(), prompt: 'select_account' })
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [])

  if (redirectUri) {
    console.log(`[Authentication] Redirect to ${redirectUri}`)
    return <Navigate to={redirectUri} replace />
  }

  if (isProfileLoaded) {
    console.log('[Authentication] Profile Available')
    return <AuthProvider user={user}>{children}</AuthProvider>
  }

  return <Authorising />
}

export default Authentication
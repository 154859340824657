import Highcharts from 'highcharts/highstock'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsAccessibility from 'highcharts/modules/accessibility'

export const colors = [
  '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db',
  '#2a6dc4', '#fd7f6f', '#7eb0d5', '#b2e061',
  '#fdcce5', '#8bd3c7',
  '#2caffe', '#544fc5', '#00e272', '#fe6a35',
  '#6b8abc', '#d568fb', '#2ee0ca', '#fa4b42',
  '#feb56a', '#91e8e1'
]

export const configure = () => {
  Highcharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ','
    }
  })
  HighchartsExporting(Highcharts)
  HighchartsExportData(Highcharts)
  HighchartsAccessibility(Highcharts)
}
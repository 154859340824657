import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Result } from 'antd'
import { createStaffPath } from '~/lib/util/navigation.util'
import { Text } from '~/lib/component/Text'

const GlobalErrorPage = () => {
  const error: any = useRouteError()
  console.error(error)

  // todo: SENTRY
  return (
    <Result
      style={{marginTop: 80}}
      status="500"
      title="Oops! An Error Occurred"
      subTitle={<>
        <Text>You are seeing this because an error occurred that we did not expect.</Text>
        <Text>We will have received a notification containing information that will help us resolve this error.</Text>
        <Text>Additionally, to help us resolve this error, we may need to contact you.</Text>

        <div>
          {error.statusText || error.message}
        </div>
      </>}
      extra={<Link to={createStaffPath()} type="primary">Go back to the dashboard</Link>}
    />
  )
}

export const Component = GlobalErrorPage
export default GlobalErrorPage
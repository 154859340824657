import React from 'react'
import { configure } from '~/lib/util/highchart.util'
import Authentication from '~/app/staff/auth/Authentication'

configure()

function Layout() {
  const AuthenticatedContent = React.lazy(() => import('./AuthenticatedContent'))
  return (
    <Authentication>
      <AuthenticatedContent />
    </Authentication>
  )
}

export const Component = Layout
export default Component